<template>
  <ExpensesTableUser
          :countFilterParams="countFilterParams"
          :filterGetParams="filterGetParams"
          @resetFilter="resetFilter"
          @changeFilter="changeFilter"
          @showMore="showMore"
          @reload="reload"
          @downloadFiles="downloadFiles"
  />
</template>

<script>
  import ExpensesTableUser from "./ExpensesTableUser/ExpensesTableUser";
  import {routeFilter} from "../../../../../mixins/routeFilterMixins/routeFilter";

  export default {
    name: "ExpensesTable",
    components: {
      ExpensesTableUser,
    },

    mixins: [routeFilter],

    data(){
      return {
        forPage: this.$store.getters.getExpensesForPage,
        page: 1,
        onePage: 1,

        filterDate: [],
        filterDocumentDate: [],
        filterUserName: '',
        filterUserId: '',
        filterId: '',
        filterName: '',
        filterTotal: '',
        filterComment: '',
        filterBank: '',
        filterExpensesType: '',
        filterExpensesTypeChild: '',
        filterPayee: '',
        filterWarehouse: '',
        filterCountry: '',

        countFilterParams: 0,
        filterGetParams: {},
      }
    },

    mounted() {
      this.filter()
    },

    methods: {

      getFilterParam() {
        this.filterGetParams = {
          page: this.$route.query.page,
          count: this.$route.query.count,
          date: this.$route.query.date,
          documentDate: this.$route.query.documentDate,
          userName: this.$route.query.userName,
          userId: this.$route.query.userId,

          id: this.$route.query.id,
          name: this.$route.query.name,
          total: this.$route.query.total,
          comment: this.$route.query.comment,
          bank: this.$route.query.bank,
          expensesType: this.$route.query.expensesType,
          expensesTypeChild: this.$route.query.expensesTypeChild,
          payee: this.$route.query.payee,
          warehouse: this.$route.query.warehouse,
          country: this.$route.query.country,
        }
      },

      refreshTable() {
        this.filter()
      },

      filter(next = false) {
        this.getFilterParam()

        let url

        // generate main filter if has get params in URL
        let generateMainFilter = this.generateMainFilterFromUrl(next, this)

        // generate other filter if has get params in URL
        this.generateParamsFilterFromUrl(this)

        // Generate filter URL
        url = this.generateFilterUrl(
          generateMainFilter.page,
          generateMainFilter.forPage
        )

        if (next) this.$store.commit('setNextExpensesPage', true)
        this.$store.dispatch('fetchExpenses', url).then(() => {
          this.$store.commit('setNextExpensesPage', false)
          this.$store.commit('setGlobalUpdateTable', false)
        })


        this.checkCountFilter(['user'])
      },

      generateFilterUrl(page, forPage) {
        let myQuery = this.createQueryBuilderObject()

        this.generateFilterQueryParams(
          myQuery,
          {
            filterId: 'ExpenseId',
            filterTotal: 'ExpenseAmount',
            filterComment: 'ExpenseComment',
            filterWarehouse: 'ExpenseWarehouseId',
            filterCountry: 'ExpenseCountryId',
            // filterExpensesType: 'byDefaultExpenseType',
            filterPayee: 'byPayeeExpenseType',
          },
        )

        console.log(2232323);
        console.log(this.filterExpensesTypeChild);
        if(this.filterExpensesTypeChild !== '') {
          myQuery.where('byDefaultExpenseType', this.filterExpensesTypeChild)
        } else if(this.filterExpensesType !== '') {
          myQuery.where('byDefaultExpenseType', this.filterExpensesType)
        }

        myQuery.where('ExpenseType', 'expense')

        if (this.filterDate.length > 0) {
          let date = this.generateDateForFilter(this.filterDate, 'YYYY-MM-DD')
          myQuery.whereIn('ExpenseCreatedAt', date)
        }

        if (this.filterDocumentDate.length > 0) {
          let date = this.generateDateForFilter(this.filterDocumentDate, 'YYYY-MM-DD')
          myQuery.whereIn('ExpenseDocumentDate', date)
        }

        return this.generateGetParamsFromQuery(myQuery, forPage, page)


      },

      downloadFiles(type) {
        let forPage, page, url, exportIds

        exportIds = this.getCheckedRows('row-name')

        // generate main filter if has get params in URL
        let generateMainFilter = this.generateMainFilterFromUrl(false, this)

        page = generateMainFilter.page
        forPage = generateMainFilter.forPage

        url = this.generateFilterUrl(page, forPage, exportIds)

        this.$store.dispatch('getExportExpenses', {
          systemType: this.filterType,
          filter: url,
          type: type
        }).then((response) => {
          const blob = new Blob([response.data], {type: `application/${type}`})
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = `Expenses.${type}`
          link.click()
          URL.revokeObjectURL(link.href)
        })

      },

    }


  }
</script>

<style scoped>

</style>
