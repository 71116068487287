<template>
  <FilterBlock :showFilter="showFilter" :title="$t('common_filterBy.localization_value.value')" @close="closeFilter"
               @changeFilter="changeFilter">
    <template slot="body">
      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['expenses_Id',])"></div>
        <DefaultInput
                :label="$t('expenses_Id.localization_value.value')"
                :type="'text'"
                v-model="id"
        />
      </div>
      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['expenses_DocumentDate',])"></div>
        <DatePickerDefault
            :label="$t('expenses_DocumentDate.localization_value.value')"
            v-model="documentDate"
        >
          <template slot="body">
            <date-picker
                v-model="documentDate"
                range
                ref="datePicker"
                valueType="format"
                :lang="$store.getters.getDatePickerTranslations[$store.getters.GET_LANG]"
                :format="'MM/DD/YY'"
                :placeholder="'mm/dd/yy'"
            ></date-picker>
          </template>
        </DatePickerDefault>
      </div>

      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['expenses_Created',])"></div>
        <DatePickerDefault
                :label="$t('expenses_Created.localization_value.value')"
                v-model="date"
        >
          <template slot="body">
            <date-picker
                    v-model="date"
                    range
                    ref="datePicker"
                    valueType="format"
                    :lang="$store.getters.getDatePickerTranslations[$store.getters.GET_LANG]"
                    :format="'MM/DD/YY'"
                    :placeholder="'mm/dd/yy'"
            ></date-picker>
          </template>
        </DatePickerDefault>
      </div>

      <div class="table-filter__item"
           v-if="isAdmin"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['expenses_TypeExpenses'])"></span>

        <DefaultSelect
            :options="expenseTypes"
            :optionsLabel="'name'"
            :label="$t('expenses_TypeExpenses.localization_value.value')"
            @change="(val) => {setExpensesType(val)}"
            :selected="expensesTypeItem"
        />
      </div>

      <div class="table-filter__item"
           v-if="isAdmin && _.has(expensesTypeItem, 'children') && expensesTypeItem.children.length > 0"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['expenses_TypeExpensesChildren'])"></span>

        <DefaultSelect
            :options="expensesTypeItem.children"
            :optionsLabel="'name'"
            :label="$t('expenses_TypeExpensesChildren.localization_value.value')"
            @change="(val) => {setExpensesTypeChild(val)}"
            :selected="expensesTypeChildItem"
        />
      </div>

      <div class="table-filter__item"
           v-if="isAdmin"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['expenses_payee'])"></span>

        <DefaultSelect
            :options="payees"
            :optionsLabel="'name'"
            :label="$t('expenses_payee.localization_value.value')"
            @change="(val) => {setPayee(val)}"
            :selected="payeeItem"
        />
      </div>

      <div class="table-filter__item"
           v-if="isAdmin"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['common_country'])"></span>

        <DefaultSelect
            :options="countries"
            :optionsLabel="'name'"
            :label="$t('common_country.localization_value.value')"
            @change="(val) => {setCountry(val)}"
            :selected="countryItem"
        />
      </div>

      <div class="table-filter__item"
           v-if="isAdmin"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['common_user'])"></span>

        <DefaultSelect
            :options="warehouses"
            :optionsLabel="'name'"
            :otherValue="'currentTranslation'"
            :label="'Warehouses'"
            @change="(val) => {setWarehouse(val)}"
            :selected="warehouseItem"
        />
      </div>

      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['expenses_Total',])"></div>
        <DefaultInput
                :label="$t('expenses_Total.localization_value.value')"
                :type="'text'"
                v-model="total"
        />
      </div>

      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['expenses_Comment',])"></div>
        <DefaultInput
                :label="$t('expenses_Comment.localization_value.value')"
                :type="'text'"
                v-model="comment"
        />
      </div>




    </template>
    <template slot="footer">
      <MainButton @click.native="resetFilter" :value="$t('common_resetFilters.localization_value.value')"
                  class="secondary wfc"/>
      <MainButton @click.native="changeFilter" :value="$t('common_applyFilters.localization_value.value')" class="wfc"/>
    </template>
  </FilterBlock>
</template>

<script>
  import FilterBlock from "../../../../../../coreComponents/FilterBlock/FilterBlock";
  import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
  import DefaultInput from "../../../../../../UI/inputs/DefaultInput/DefaultInput";
  import DatePickerDefault from "../../../../../../UI/inputs/DatePickerDefault/DatePickerDefault";
  import DatePicker from 'vue2-datepicker'
  import {filterFunc} from "../../../../../../../mixins/filterFuncMixin/filterFunc";
  import {EXPENSES_SYSTEM_TYPES} from "../../../../../../../staticData/staticVariables";
  import DefaultSelect from "../../../../../../UI/selectiones/DefaultSelect/DefaultSelect";

  export default {
    name: "ExpensesFilter",
    components: {
      DefaultSelect,
      FilterBlock,
      MainButton,
      DatePickerDefault,
      DatePicker,
      DefaultInput,
    },

    props: [
      'showFilter',
      'filterGetParams',
    ],

    mixins: [filterFunc],

    data() {
      return {
        date: this.filterGetParams.date ? this.generateFilterDate(this.filterGetParams.date, 'MM/DD/YY') : '',
        documentDate: this.filterGetParams.documentDate ? this.generateFilterDate(this.filterGetParams.documentDate, 'MM/DD/YY') : '',
        id: this.filterGetParams.id ? this.filterGetParams.id : '',
        name: this.filterGetParams.name ? this.filterGetParams.name : '',
        total: this.filterGetParams.total ? this.filterGetParams.total : '',
        comment: this.filterGetParams.comment ? this.filterGetParams.comment : '',
        bank: this.filterGetParams.bank ? this.filterGetParams.bank : '',
        expensesType: this.filterGetParams.expensesType ? this.filterGetParams.expensesType : '',
        expensesTypeChild: this.filterGetParams.expensesTypeChild ? this.filterGetParams.expensesTypeChild : '',
        payee: this.filterGetParams.payee ? this.filterGetParams.payee : '',
        warehouse: this.filterGetParams.warehouse ? this.filterGetParams.warehouse : '',
        country: this.filterGetParams.country ? this.filterGetParams.country : '',

        userName: this.filterGetParams.userName ? this.filterGetParams.userName : '',
        userId: this.filterGetParams.userId ? this.filterGetParams.userId : '',

        options: [],
        warehouses: [],
        countries: [],
        expenseTypes: [],
        banks: [],
        payees: [],

        warehouseItem: [],
        countryItem: [],
        expensesTypeItem: [],
        expensesTypeChildItem: [],
        bankTypeItem: [],
        payeeItem: [],

        filterCounts: [
          'date',
          'documentDate',
          'id',
          'name',
          'total',
          'comment',
          'bank',
          'expensesType',
          'expensesTypeChild',
          'payee',
          'warehouse',
          'country',

          'userName',
          'userId',
        ],
      }
    },

    created() {
      this.$store.dispatch('getExpensesCreate').then(response => {

        let respData = this.getRespData(response)
        this.warehouses = respData.warehouses
        this.countries = respData.countries
        this.expenseTypes = respData.expense_type_instances[EXPENSES_SYSTEM_TYPES.DEFAULT_TYPE.value]
        this.banks = respData.expense_type_instances[EXPENSES_SYSTEM_TYPES.BANK_TYPE.value]
        this.payees = respData.expense_type_instances[EXPENSES_SYSTEM_TYPES.PAYEE_TYPE.value]

        if(this.expensesType !== '') {
          this.expensesTypeItem = this._.find(this.expenseTypes, {id: Number(this.expensesType)})

          if(this.expensesTypeChild !== '') {
            this.expensesTypeChildItem = this._.find(this.expensesTypeItem?.children, {id: Number(this.expensesTypeChild)})
          }
        }
      }).catch(error => this.createErrorLog(error))
    },

    watch: {
      filterGetParams(newVal) {
        this.date = newVal.date ? this.generateFilterDate(newVal.date, 'MM/DD/YY') : ''
        this.documentDate = newVal.documentDate ? this.generateFilterDate(newVal.documentDate, 'MM/DD/YY') : ''
        this.id = newVal.id ? newVal.id : ''
        this.name = newVal.name ? newVal.name : ''
        this.total = newVal.total ? newVal.total : ''
        this.comment = newVal.comment ? newVal.comment : ''
        this.bank = newVal.bank ? newVal.bank : ''
        this.expensesType = newVal.expensesType ? newVal.expensesType : ''
        this.expensesTypeChild = newVal.expensesTypeChild ? newVal.expensesTypeChild : ''
        this.payee = newVal.payee ? newVal.payee : ''
        this.warehouse = newVal.warehouse ? newVal.warehouse : ''
        this.country = newVal.country ? newVal.country : ''

        this.userName = newVal.userName ? newVal.userName : ''
        this.userId = newVal.userId ? newVal.userId : ''
      },

    },

    methods: {
      closeFilter() {
        this.$emit('close')
      },

      resetFilter() {
        let data = this.resetFilterParams(this, this.filterCounts)
        this.payeeItem = null
        this.warehouseItem = null
        this.countryItem = null
        this.expensesTypeItem = null
        this.expensesTypeChildItem = null

        this.$emit('changeFilter', data)
      },

      changeFilter() {
        let data = this.changeFilterParams(this, this.filterCounts)
        data.date = this.changeDateParams(this, 'date')
        data.documentDate = this.changeDateParams(this, 'documentDate')

        this.$emit('changeFilter', data)
      },

      setWarehouse(val) {
        this.warehouse = val.id
        this.warehouseItem = val
      },

      setExpensesType(val) {
        this.expensesType = val.id
        this.expensesTypeItem = val
      },

      setExpensesTypeChild(val) {
        this.expensesTypeChild = val.id
        this.expensesTypeChildItem = val
      },

      setPayee(val) {
        this.payee = val.id
        this.payeeItem = val
      },

      setCountry(val) {
        this.country = val.id
        this.countryItem = val
      },
    },
  }
</script>

<style scoped>

</style>
