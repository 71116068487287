<template>
  <div class="fragment">

    <div class="content-top-line content-top-line--with-bottom-line ">
      <div class="content-tabs content-tabs--separator">

      </div>
      <div class="content-top-line-wrap">
        <ToggleFilterButton
                :count="countFilterParams"
                :showFilter="showFilter"
                @toggleFilter="$emit('toggleFilter')"
        />
      </div>
    </div>

    <div class="light-bg-block">
      <div class="btn-left-block">
<!--        <DefaultCheckbox-->
<!--            :label="$t('common_selectAll.localization_value.value')"-->
<!--            @input="(val) => $emit('selectAll', val)"-->
<!--        />-->
      </div>
      <div class="btn-right-block">

        <div class="content-top-line-wrap import-btn ml-2"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate([
          'common_import',
        ])"></span>
          <TableGeadButton
              :value="$t('common_import.localization_value.value')"
              :ico="'import'"
              @click.native="changeImportPopup(true)"
          />
          <div class="likeTooltipBtn" style="margin-left: -10px;">
            <TableGeadButton
                :value="''"
                :ico="'question'"
                class="likeTooltipBtn__item"
                @click.native="downloadExample"
            />
          </div>
        </div>

        <div class="ml-2"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['common_AddNew',])"></div>
          <router-link :to="$store.getters.GET_PATHS.incomeExpensesNewExpense">
            <MainButton class="btn-fit-content ml-2"
                        :value="$t('common_AddNew.localization_value.value')"
                        :ico="true"
            >
              <template slot="ico">
                <PlusIconSVG class="btn-icon-plus" />
              </template>
            </MainButton>
          </router-link>
        </div>
      </div>
    </div>


    <ImportPopup
        v-if="isModalImportPopup"
        @close="changeImportPopup(false)"
        @reloadTable="$emit('reload')"
    />

  </div>
</template>

<script>
  import ToggleFilterButton from "../../../../../../UI/buttons/ToggleFilterButton/ToggleFilterButton";
  import PlusIconSVG from '../../../../../../../../public/img/UI-group/plus-icon.svg?inline'
  import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
  import TableGeadButton from "@/components/coreComponents/TableComponents/TableHeadButton/TableHeadButton";
  import ImportPopup from "@/components/modules/ExpensesModule/popups/ImportPopup/ImportPopup";
  // import DefaultCheckbox from "../../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";


  export default {
    name: "ExpensesHead",
    components: {
      ImportPopup,
      TableGeadButton,
      // DefaultCheckbox,
      ToggleFilterButton,
      PlusIconSVG,
      MainButton,
    },

    props: {
      showFilter: Boolean,
      countFilterParams: Number,
    },

    created() {

    },

    data() {
      return {
        isModalImportPopup: false,
      }
    },

    methods: {

      changeImportPopup(val){
        this.isModalImportPopup = val
      },

      downloadExample() {
        this.$store.dispatch('getImportExampleXLSXExpenses').then((response) => {
          if (this._.has(response, 'data') && response.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
            const blob = new Blob([response.data], {type: 'application/xlsx'})
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = 'Expenses_import_example.xlsx'
            link.click()
            URL.revokeObjectURL(link.href)
          } else {
            setTimeout(() => {
              this.openNotify('error', 'common_notificationUndefinedError')
            }, 200)
          }
        })

      },
    }
  }
</script>

<style scoped>

@media (max-width: 767px){
  .content-top-line-wrap.import-btn {
    top: 0;
  }

  .likeTooltipBtn {
    margin-left: 0 !important;
  }
}

@media (max-width: 550px) {
  .content-top-line-wrap >>> .table-head-btn__btn {
    font-size: 15px;
    padding-left: 16px;
  }
}
</style>

